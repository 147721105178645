import React from "react";
import './Projects.css';

import Slider from "react-slick";
import { projects } from "../../constants/constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Projects = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="app__projects section__padding" id="projects">
      <div className="app__section_divider" />
      <div className="app__title">Projects</div>
      
      <Slider {...sliderSettings} className="app__container ">
        {projects.map(
          ({ id, image, title, description, tags, source, visit }) => (
            <div className="app__projects-blogcard" key={id}>
              <div className="app__projects-text">
                <div className="app__projects-project-title">{title}</div>
                <div className="app__projects-description">
                  {description}

                  <div className="app__projects-stack">
                    <h3> Stack</h3>
                    <div className="app__projects-tag">
                      {tags.map((tag, index) => {
                        const TagIcon = tag.icon;
                        return (
                          <TagIcon
                            key={index}
                            className="app__projects-tag-icon"
                          />
                        );
                      })}
                    </div>
                  </div>

                  <div className="app__projects-links">
                    <li>
                      {" "}
                      <a href={source}>Source</a>
                    </li>
                    <li>
                      <a href={visit} target="_blank">Visit</a>
                    </li>
                  </div>
                </div>
              </div>
              <img src={image} alt=" project images" />
              <div></div>
            </div>
          )
        )}
      </Slider>
    </div>
  );
};

export default Projects;
