import React from 'react';
import './Header.css';
const Header = () => {
  return (
    <div className='app__hearder section__padding' id='home'>
      <h1 className='app__header-h1'>Welcome to Kentohe,<br/>where culture meets technology.</h1>
      <p className='app__header-p'>At Kentohe, we believe that the web has the power to connect people and cultures from around the world. That's why we're dedicated to creating innovative web solutions that bridge the gap between tradition and technology. 
      Whether you're looking to build a stunning website or a powerful app, we have the expertise and experience to help you succeed in the digital age.</p>
      <button className='app__header-button'  onClick={() => window.location = 'mailto:hello@kentohe.com'}>Learn more</button>
    </div>
  )
}

export default Header
