import { FaShopify, FaJs, FaHtml5, FaCss3, FaWordpress, FaPhp } from 'react-icons/fa';

export const projects = [{

    title: 'Sports forward',
    description: "We partnered with a prominent sports figure to create a WordPress website showcasing their passion for athletics and fairness. Leveraging their experience as Olympic commentators and World Games media coordinators, we designed a dynamic site highlighting multi-sport events, resonating with athletes, organizers, and fans.",
    image: '/images/Mockup_Assembled_Sports-forward_Home-1.png',
    tags: [
        { name: 'JavaScript', icon: FaJs },
        { name: 'Html', icon: FaHtml5 },
        { name: 'CSS', icon: FaCss3 },
        { name: 'CSS', icon: FaWordpress },
        { name: 'CSS', icon: FaPhp },
    ],
    source: '/',
    visit: 'https://sports-forward.de/',
    id: 0,
  
},

    {
        title: 'Juenglinge',
        description: "We partnered with Jünglinge Film, a Berlin-based artist-led production company, to create a bespoke WordPress website. Specializing in film, TV, and digital projects, they pioneer 'activist popcorn cinema,' exploring identity and community with a bold feminist, anti-racist, and queer storytelling approach.",
        image: '/images/Animated_Assembled_Juenglinge_33percent_Half-Speed.gif',
        tags: [
            { name: 'JavaScript', icon: FaJs },
            { name: 'Html', icon: FaHtml5 },
            { name: 'CSS', icon: FaCss3 },
            { name: 'CSS', icon: FaWordpress },
            { name: 'CSS', icon: FaPhp },
        ],
        source: '/',
        visit: 'https://juenglinge-film.de/',
        id: 1,
    },
    {
  
        title: 'Olivia Ballard',
        description: " Custom e-commerce webshop using Shopify for a Berlin-based label that creates unisex clothing with a focus on the sensual experience of textiles. I customized the front-end design and added JavaScript functionalities to improve user experience. Check it out for a glimpse of my design and development skills!",
        image: '/images/Animated-Assembled_OB_33percent.gif',
        tags: [
            { name: 'Shopify', icon: FaShopify },
            { name: 'JavaScript', icon: FaJs },
            { name: 'Html', icon: FaHtml5 },
            { name: 'CSS', icon: FaCss3 }
        ],
        // tags: ['Shopify', 'JavaScript', 'Html', 'CSS'],
        // source: 'https://oliviaballard.com/',
        // visit: 'https://oliviaballard.com/',
        id: 2,
    }
];

export const TimeLineData = [{
        year: 2019,
        text: 'Started my journey',
    },
    {
        year: 2019,
        text: 'Worked as a Junior Full Stack Web Developer, Clevver GmbH, Berlin',
    },
    {
        year: 2022,
        text: 'Founded Kentohe Agency',
    },
    {
        year: 2023,
        text: 'Worked as a Freelance Frontend Developer (Self-Employed)',
    },
    {
        year: 2024,
        text: 'Shared my projects with the world',
    },
];