import React, { useState } from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    message: ''
  });
  const [message, setMessage] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null); // State to hold reCAPTCHA value

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!recaptchaValue) {
      setMessage('Please complete the reCAPTCHA.');
      return;
    }

    emailjs.send('service_dr3f2hr', 'template_pt5agnc', formData, 'AxGH5KQuHwO7OTE0D')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setMessage('Your email has been successfully sent.');
        setFormData({ from_name: '', from_email: '', message: '' });
      })
      .catch((err) => {
        console.error('FAILED...', err);
        setMessage('Failed to send message. Please try again.');
      });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <div className='section__padding' id='contact'>
      <div className='app__section_divider' />
      <div className='app__title'>Contact</div>
      <div className='app__container'>
        <p className='app__technologies-p'><span>Get in Touch for a Quote!</span> <br />
          We are excited to help you with your project. Please fill out the form below to get a personalized quote. Our team is multilingual and can assist you in Portuguese, German, French, Spanish, and English.
        </p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-field">
            <label htmlFor="from_name">Name</label>
            <input type="text" id="from_name" name="from_name" value={formData.from_name} onChange={handleChange} required />
          </div>
          <div className="form-field">
            <label htmlFor="from_email">Email</label>
            <input type="email" id="from_email" name="from_email" value={formData.from_email} onChange={handleChange} required />
          </div>
          <div className="form-field">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
          </div>
          <ReCAPTCHA
            sitekey="6LcN6fopAAAAAI6KeJU7d-c6exRnbVuLAxISSebU"
            onChange={handleRecaptchaChange}
          />
          <button type="submit" className="submit-button">Send</button>
        </form>
        {message && <p className="form-message">{message}</p>}
      </div>
    </div>
  );
}

export default Contact;
