import React from 'react';

import { About, Footer, Header, Projects, Technologies, Contact } from './container';
import { Navbar } from './components';
import './App.css';

const App = () => (
   <div>
     <Navbar />
     <Header />
     <Projects />
     <Technologies />
     <About />
     <Contact />
     <Footer />
   </div>
  )

export default App
