import React from 'react';
import { RiMenu3Line , RiCloseLine } from 'react-icons/ri';
import images from '../../constants/images';
import { AiFillInstagram } from 'react-icons/ai';
import './Navbar.css';

const Navbar = () => {
const [toggleMenu, setToggleMenu] = React.useState(false);
return (
<nav className='app__navbar'>
  <div className='app__navbar-logo'>
    <a href='#home"' ><img src={images.kentohe} alt="app logo" /> </a>
  </div>
  <ul className='app__navbar-links'>
    <li className='p__opensans'><a href="#projects">Projects</a></li>
    <li className='p__opensans'><a href="#technologies">Technologies</a></li>
    <li className='p__opensans'><a href="#about">About</a></li>
    <li className='p__opensans'><a href="#contact">Contact Us</a></li>

  </ul>
  <ul className='app__navbar-socials-links bigscreem_social-links'>
    <li><a href="https://www.instagram.com/kentoheagency/" target="_blank" rel="noreferrer">
        < AiFillInstagram size={"1.5rem"} /> </a></li>
  </ul>
  <div className='app__navbar-smallscreen'>
    <RiMenu3Line color='#fff' fontSize={27} onClick={()=> setToggleMenu(true)}/>
      {toggleMenu && (
      <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
        <RiCloseLine fontSize={27} onClick={()=> setToggleMenu(false)} className='overlay__close' />
          <ul className='app__navbar-smallscreen-links'>
          <li className='p__opensans'><a href="#home">Home</a></li>
            <li className='p__opensans'><a href="#projects">Projects</a></li>
            <li className='p__opensans'><a href="#technologies">Technologies</a></li>
            <li className='p__opensans'><a href="#about">About</a></li>
            <li className='p__opensans'><a href="#contact">Contact Us</a></li>
          </ul>
          <ul className='app__navbar-socials-links smallscreen_socials-links'>
            <li><a href="https://www.instagram.com/kentoheagency/" target="_blank" rel="noreferrer">
                < AiFillInstagram /> </a></li>
          </ul>
      </div>
      )}
  </div>
</nav>
)
}

export default Navbar