import React from 'react';
import './Technologies.css';
import { DiReact } from 'react-icons/di';
import { FaPhp, FaWordpress, FaShopify, FaCss3, FaHtml5,FaJs, FaNodeJs  } from "react-icons/fa";
import { BsFiletypeSql } from "react-icons/bs";




const Technologies = () => {
  return (
    <div className='technologies section__padding' id='technologies'>
    <div className='app__section_divider' />
    <div className='app__title'>Technologies</div>
    <div className='app__container'>
      <p className='app__technologies-p'>We're passionate about leveraging cutting-edge technologies to bring your digital visions to life. From dynamic web applications to robust e-commerce solutions, we specialize in a diverse array of technologies, ensuring that every project is tailored to your unique needs.</p>
    <br />
    <p>Our toolkit includes:</p> 

   <div className='app__technologies-list'>
    <li><DiReact size="3rem" />
    <h3>Front-End</h3>
    <p>Dynamic front-end  <br />
    interfaces.</p>
    </li>
<li><FaNodeJs size="3rem" />
<h3>Backend</h3>
<p>  Scalable backend <br />applications </p>

</li>
<li> <FaPhp size="3rem"/>
<h3>Backend</h3>
<p>  Powerful server-side <br />scripting. </p>
</li>

<li> <FaShopify size="3rem"/>
<h3>Backennd</h3>
<p>Tailored e-commerce <br />platforms.</p>
</li>
<li> <FaWordpress size="3rem"/>
<h3>Backend</h3>
<p>Customizable website<br /> solutions</p>

</li>
<li> <FaHtml5 size="3rem"/>

<h3>Front-end</h3>
<p>Structuring web<br /> content.</p>
</li>

<li> <FaCss3 size="3rem"/>
<h3>Front-end</h3>
<p>Styling web  elements.</p>
</li>
<li> <FaJs size="3rem"/>
<h3>Front-end</h3>
<p>Interactive web<br /> experiences.</p></li>
<li> <BsFiletypeSql size="3rem"/>
<h3>Backend</h3>
<p>Efficient database<br /> management.</p></li>
   </div>
   <p className='app__technologies-p'>Whether you're envisioning a sleek portfolio website, a feature-rich web application, or a thriving online store, we're here to turn your ideas into reality. Let's build something amazing together.</p>
    </div>

  </div>
  )
}

export default Technologies
